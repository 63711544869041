const hostedZone = process.env.HOSTED_ZONE || '';

export const customDomainName = process.env.CUSTOM_DOMAIN_NAME || '';

export const fullStoryOrganizationId = process.env.NEXT_PUBLIC_FULLSTORY_ORGANIZATION_ID || '';
export const googleAnalyticsID = process.env.NEXT_PUBLIC_GA_ID || '';
export const googleTagManagerID = process.env.NEXT_PUBLIC_GTM_ID || '';
export const nodeEnvironment = process.env.NODE_ENV || 'development';
export const isSearchEngineIndexable = process.env.SEARCH_ENGINE_INDEXABLE || 'false';
export const sentryDns = process.env.NEXT_PUBLIC_SENTRY_DSN || '';
export const isNewsFeatureFlagEnabled = process.env.NEXT_PUBLIC_FEATURE_FLAG_NEWS_PAGE === 'true';
export const isSelfServiceFeatureFlagEnabled =
  process.env.NEXT_PUBLIC_FEATURE_FLAG_SELF_SERVICE_PAGE === 'true';
export const isCostPlusPageFeatureFlagEnabled =
  process.env.NEXT_PUBLIC_FEATURE_FLAG_COSTPLUS_PAGE === 'true';
export const isMedicationPagesFeatureFlagEnabled =
  process.env.NEXT_PUBLIC_FEATURE_FLAG_MEDICATION_PAGES === 'true';
export const contentfulSpaceId = process.env.CONTENTFUL_SPACE_ID || '';
export const contentfulAccessToken = process.env.CONTENTFUL_ACCESS_TOKEN || '';
export const contentfulEnvironment = process.env.CONTENTFUL_ENVIRONMENT || 'develop';

export const isLocalEnvironment = nodeEnvironment === 'development';
export const isProductionEnvironment =
  process.env.NEXT_PUBLIC_APP_STAGE === 'production' || process.env.STACK_STAGE === 'production';
export const agileApiUrl = process.env.API_URL || '';

export const customersTableName = process.env.CUSTOMERS_TABLE_NAME || '';

export const submittedPatientsQueueUrl = process.env.SUBMITTED_PATIENTS_QUEUE_URL || '';
export const updateSubscriptionQueueUrl = process.env.UPDATE_SUBSCRIPTION_QUEUE_URL || '';
export const submittedRxOrderQueueUrl = process.env.SUBMITTED_RX_ORDER_QUEUE_URL || '';
export const updatedSubscriptionQueueUrl = process.env.UPDATED_SUBSCRIPTION_EVENT_QUEUE_URL || '';
export const submittedFormAnswerGroupQueueUrl =
  process.env.SUBMITTED_FORM_ANSWER_GROUP_QUEUE_URL || '';
export const submittedDocumentQueueUrl = process.env.SUBMITTED_DOCUMENT_QUEUE_URL || '';

export const healthieApiKey = process.env.HEALTHIE_API_KEY || '';
export const healthieApiUrl = process.env.HEALTHIE_API_URL || '';

export const agileNoReplyEmailAddress = `no-reply@${hostedZone}`;
export const developmentEmailReceiver = process.env.DEVELOPMENT_EMAIL_RECEIVER;
export const stripeSecretKey = process.env.STRIPE_SECRET_KEY || '';
export const stripePublishableKey = process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || '';
export const stripeWebhookSecret = process.env.STRIPE_WEBHOOK_SECRET || '';
